import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import ContactViewVue from '../views/ContactView.vue';
import HomeView from '../views/HomeView.vue';
const routes: RouteRecordRaw[] = [
    {
        path: '/', 
        component: HomeView,
        meta: { transition: 'fade' }
    },
    {
        path: '/contacts',
        component: ContactViewVue,
        meta: { transition: 'fade' }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    },
];
const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;